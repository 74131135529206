import Banner from './Banner'
import Cart from './Cart'

function App() {
	return (
		<div>
			<Banner />
			<Cart />
		</div>
	)
}

export default App
